import * as React from "react";

import { Item } from "./style";

interface Props<I> {
  items: I[];
  children: (item: I) => React.ReactNode;
  keyFinder: (item: I) => string;
}

export default function AnimatedList<I>({
  items,
  children,
  keyFinder
}: Props<I>) {
  return (
    <>
      {items.map((item, index) => (
        <Item key={keyFinder(item)} index={index}>
          {children(item)}
        </Item>
      ))}
    </>
  );
}
