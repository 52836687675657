import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.98);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

interface Animation {
  index: number;
}

export const Item = styled.div`
  animation: ${fadeIn} 250ms ease-in-out forwards;
  animation-delay: ${({ index }: Animation) => (index * 100) / 1.5}ms;
  opacity: 0;
  transform: scale(0.98);
`;
