import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";

import Layout from "../components/layout";
import Form from "../components/form";
import Title from "../components/title";
import { Query } from "../graphql-types";
import PageContainer from "../components/page-container";
import SEO from "../components/seo";

interface Props {
  data: Query;
}

const Container = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Block = styled.div`
  flex-grow: 1;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
  width: 95%;

  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
`;

export default function Contact({ data }: Props) {
  const contactPage = data.allContentfulContactPage.edges
    .map(e => e.node)
    .find((e, i) => i === 0);

  return (
    <Layout>
      <SEO data={contactPage.seo} />>
      <PageContainer>
        <Title>Parlons de votre projet</Title>
        <Container>
          <Block>
            <Img fluid={contactPage.picture.fluid} />
          </Block>
          <Block>
            <FormContainer>
              <div style={{ width: "100%" }}>
                <Form form={contactPage.form} />
              </div>
            </FormContainer>
          </Block>
        </Container>
      </PageContainer>
    </Layout>
  );
}

export const query = graphql`
  {
    allContentfulContactPage {
      edges {
        node {
          seo {
            ...SEO
          }
          picture {
            fluid(maxWidth: 1400, quality: 80) {
              ...GatsbyContentfulFluid
            }
          }
          form {
            id
            name
            submitButton
            contentfulfields {
              id
              name
              textarea
              required
              label
              placeholder
              type
            }
          }
        }
      }
    }
  }
`;
