import * as React from "react";
import styled from "styled-components";
import Recaptcha from "react-google-recaptcha";
import { navigate } from "gatsby";

import FormGroup from "../form-group";
import Label from "../label";
import Input from "../input";
import Textarea from "../textarea";

import OButton from "../button";
import { ContentfulForm } from "../../graphql-types";
import AnimatedList from "../animated-list";

const Button = styled(OButton.withComponent("button"))`
  margin-top: 20px;
`;

const RECAPTCHA_KEY = "6LfwuYUUAAAAABHUTAKcrjqjQ3TAXs00tQGOdOPX";

function encode(data: { [key: string]: string }) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

interface Props {
  form: ContentfulForm;
}

interface Fields {
  [key: string]: string;
}

interface State {
  fields: Fields;
  "g-recaptcha-response": string;
}

export default class Form extends React.PureComponent<Props, State> {
  state = {
    fields: {},
    "g-recaptcha-response": ""
  };

  changeField = ({
    currentTarget: target
  }: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [target.name]: target.value
      }
    }));
  };

  handleRecaptcha = (value: string | null) => {
    this.setState({ "g-recaptcha-response": value });
  };

  submit = async (e: any) => {
    e.preventDefault();
    const form = e.target;

    try {
      const test = await fetch("https://www.dankana.fr/contact", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": this.props.form.name,
          "g-recaptcha-response": this.state["g-recaptcha-response"],
          ...this.state.fields
        })
      });

      console.log(test);

      navigate(form.getAttribute("action"));
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { form } = this.props;
    return (
      <form
        name={form.name}
        method="POST"
        onSubmit={this.submit}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value={form.name} />
        <AnimatedList items={form.contentfulfields} keyFinder={f => f.id}>
          {field => (
            <FormGroup key={field.id}>
              <Label htmlFor={field.name}>{field.label}</Label>
              {field.textarea ? (
                <Textarea
                  id={field.name}
                  name={field.name}
                  placeholder={field.placeholder}
                  required={field.required}
                  onChange={this.changeField}
                />
              ) : (
                <Input
                  id={field.name}
                  name={field.name}
                  placeholder={field.placeholder}
                  type={field.type}
                  required={field.required}
                  onChange={this.changeField}
                />
              )}
            </FormGroup>
          )}
        </AnimatedList>

        <Recaptcha
          ref="recaptcha"
          sitekey={RECAPTCHA_KEY}
          onChange={this.handleRecaptcha}
        />
        <Button type="submit">{form.submitButton}</Button>
      </form>
    );
  }
}
