import styled from "styled-components";

const Input = styled.input`
  color: black;
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 0 0 10px 0;
  margin: 0;
  box-sizing: border-box;
  border: none;
  border-bottom: solid 1px black;
  outline: 0;
  font-family: Fira Sans;

  &:focus {
    border-bottom: solid 2px #92b6b4;
  }
`;

export default Input;
